import React, {useEffect, useState} from 'react';
import {Toast, Button} from 'react-bootstrap';

function SuccessToast({status}) {
    const [show, setShow] = useState(status);

    const toggleShow = () => setShow(!show);

    useEffect(() => {
        setShow(status);
    }, [status])

    return (
        <>
            <Toast
                show={show}
                // bg={'success'}
                onClose={toggleShow}
                delay={5000}
                autohide
                style={{position: 'absolute', top: 20, right: 20}}
            >
                <Toast.Header>
                    <strong className="me-auto">Zakaz yuborildi</strong>
                    <small>Zarin</small>
                </Toast.Header>
                <Toast.Body>
                    <i className={'fa-regular fa-circle-check text-success'} style={{fontSize: 200}}></i>
                </Toast.Body>
            </Toast>
        </>
    );
}

export default SuccessToast;
