import Button from "react-bootstrap/Button";

const Basket = ({products, setProducts}) => {

    const onDelete = (id) => {
        const updatedProducts = products.filter(product => product.id !== id);
        localStorage.setItem('products', JSON.stringify(updatedProducts));
        setProducts(updatedProducts);
    };

    return (

        <div className={'table-responsive'}>
            <table className={'table table-striped'}>
                <thead>
                <tr>
                    <th>N</th>
                    <th>Nomi</th>
                    <th>Barcode</th>
                    <th>Miqdor</th>
                    <th>Narx</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    products.map((product, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{product.firma} {product.nomi}</td>
                            <td>{product.barcode}</td>
                            <td>{product.miqdor}</td>
                            <td>{product.sot_narx} {product.sot_val}</td>
                            <td>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => onDelete(product.id)}
                                >
                                    <i className={'fas fa-trash'}></i>
                                </Button>
                            </td>
                        </tr>
                    ))
                }


                </tbody>
            </table>
        </div>
    );
}

export default Basket;
