import logo from './logo.svg';
import './App.css';
import BarcodeScanner from "./components/BarcodeScanner";
import Basket from "./components/Basket";

function App() {




    return (
        <div className="App container">
            <BarcodeScanner/>
        </div>
    );
}

export default App;
