import MijozModal from "./MijozModal";
import {useEffect, useState} from "react";
import SuccessToast from "./SuccessToast";


const CreateZakaz = ({products, setProducts}) => {

    const [mijozModal, setMijozModal] = useState(false);

    const modalShow = () => {
        setMijozModal(true);
    }
    const modalHide = () => {
        setMijozModal(false);
    }

    return (
        <>
            <div className="createZakaz">
                <button className="btn btn-warning w-100"
                        onClick={modalShow}
                        disabled={products.length === 0}
                >
                    <i className="fas fa-shopping-basket me-2"></i>
                    Zakaz berish
                </button>
            </div>


            <MijozModal
                setProducts={setProducts}
                mijozModalShow={mijozModal}
                modalHide={modalHide}
            />
        </>
    )
}

export default CreateZakaz;